/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { SanityWriting } from "types";
import { SEO } from "components";
import BlockContent from "@sanity/block-content-to-react";
import styles from "./writing.module.scss";

const Writing: React.FC<{ pageContext: { data: SanityWriting } }> = ({
  pageContext: { data: writing },
}) => {
  return (
    <>
      <SEO
        title={writing.information!.title}
        description={writing.information.shortDescription}
      />
      <main className={styles.main}>
        <BlockContent
          className={styles.content}
          blocks={writing.information.content}
          dataset="production"
          projectId="jcmgoa34"
          renderContainerOnSingleChild={true}
          serializers={{
            container: "article",
            types: {
              singleImage: (): string => ``,
              multiImage: (): string => ``,
            },
          }}
        />
      </main>
    </>
  );
};

export default Writing;
